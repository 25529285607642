import service from './request'

// 新增广告接口
const addAPI = data => {
    return service({
        url: `/manage/adv/add`,
        method: 'post',
        data
    })
}

// 广告表格数据接口
const tableListAPI = data => {
    return service({
        url: `/manage/adv/list`,
        method: 'get',
        data
    })
}

// 删除广告
const tableDeleteAPI = data => {
    return service({
        url: `/manage/adv/delete/${data.advId}`,
        method: 'post',
        data
    })
}

// 编辑广告
const tableUpdateAPI = data => {
    return service({
        url: `/manage/adv/operation`,
        method: 'post',
        data
    })
}

// 表格排序接口
const tableDragAPI = data => {
    return service({
        url: `/manage/adv/adjustRank`,
        method: 'post',
        data
    })
}

export {
    tableListAPI,
    addAPI,
    tableDeleteAPI,
    tableUpdateAPI,
    tableDragAPI
}