var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.pageLoading,
          expression: "pageLoading",
        },
      ],
      staticClass: "advertisementManage",
    },
    [
      _vm.channelList.length
        ? [
            _c(
              "el-tabs",
              {
                attrs: { type: "card" },
                on: { "tab-click": _vm.handleClick },
                model: {
                  value: _vm.channelNo,
                  callback: function ($$v) {
                    _vm.channelNo = $$v
                  },
                  expression: "channelNo",
                },
              },
              _vm._l(_vm.channelList, function (item, index) {
                return _c("el-tab-pane", {
                  key: index,
                  attrs: { label: item.channelName, name: item.channelNo },
                })
              }),
              1
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.addFun },
              },
              [_vm._v("添加")]
            ),
            _c("Table", {
              attrs: {
                "table-data": _vm.tableData,
                "title-name": _vm.titleName,
                operation: true,
                "col-class-type": true,
                "drag-onoff": true,
                "operation-width": "150",
              },
              on: {
                "update:tableData": function ($event) {
                  _vm.tableData = $event
                },
                "update:table-data": function ($event) {
                  _vm.tableData = $event
                },
                dragFinsh: _vm.dragFinsh,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          [
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    size: "small",
                                    sort: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.updateBtn(scope.scopeRow)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                            ],
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    size: "small",
                                    sort: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.seeFun(scope.scopeRow)
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                            ],
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    size: "small",
                                    sort: "danger",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteFun(scope.scopeRow)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ],
                          ],
                          2
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3669063610
              ),
            }),
          ]
        : _vm.emptyFlag
        ? _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                "font-size": "18px",
                "text-align": "center",
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/images/home/empty.png"),
                  alt: "",
                },
              }),
              _c("div", [_vm._v("暂无权限，请联系管理员")]),
            ]
          )
        : _vm._e(),
      _vm.show
        ? _c(
            "charterPopup",
            {
              attrs: {
                width: "820",
                disabled: _vm.disabled,
                "header-text": _vm.headerText,
              },
              on: {
                closePopupFun: _vm.closePopupFun,
                confirmFun: _vm.confirmFun,
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "pointForm",
                  staticClass: "demo-form dialog-form",
                  attrs: {
                    model: _vm.pointForm,
                    rules: _vm.rules,
                    "label-width": "140px",
                    "label-position": "right",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { display: "block" },
                      attrs: { label: "广告描述：", prop: "advDesc" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "200",
                          disabled: _vm.disabled,
                          type: "textarea",
                        },
                        model: {
                          value: _vm.pointForm.advDesc,
                          callback: function ($$v) {
                            _vm.$set(_vm.pointForm, "advDesc", $$v)
                          },
                          expression: "pointForm.advDesc",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "广告类型：", prop: "advType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                          },
                          on: { change: _vm.advTypeChange },
                          model: {
                            value: _vm.pointForm.advType,
                            callback: function ($$v) {
                              _vm.$set(_vm.pointForm, "advType", $$v)
                            },
                            expression: "pointForm.advType",
                          },
                        },
                        _vm._l(_vm.advertisementClassList, function (items) {
                          return _c("el-option", {
                            key: items.value,
                            attrs: { label: items.label, value: items.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "位置：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          "seat-value": _vm.seatValue,
                          disabled: "disabled",
                        },
                        model: {
                          value: _vm.pointForm.position,
                          callback: function ($$v) {
                            _vm.$set(_vm.pointForm, "position", $$v)
                          },
                          expression: "pointForm.position",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效期起：", prop: "startDate" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: _vm.disabled,
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          placeholder: "选择时间",
                        },
                        model: {
                          value: _vm.pointForm.startDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.pointForm, "startDate", $$v)
                          },
                          expression: "pointForm.startDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "有效期止：", prop: "deadDate" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled: _vm.disabled,
                          format: "yyyy-MM-dd HH:mm:ss",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          type: "datetime",
                          "default-time": "23:59:59",
                          placeholder: "选择时间",
                        },
                        model: {
                          value: _vm.pointForm.deadDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.pointForm, "deadDate", $$v)
                          },
                          expression: "pointForm.deadDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "点击跳转：", prop: "jumpLink" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "请选择",
                          },
                          on: { change: _vm.jumpLinkChange },
                          model: {
                            value: _vm.pointForm.jumpLink,
                            callback: function ($$v) {
                              _vm.$set(_vm.pointForm, "jumpLink", $$v)
                            },
                            expression: "pointForm.jumpLink",
                          },
                        },
                        _vm._l(_vm.jumpLinkList, function (items) {
                          return _c("el-option", {
                            key: items.value,
                            attrs: {
                              label: items.label,
                              value: items.value,
                              disabled:
                                _vm.pointForm.advType == 3
                                  ? items.value === 0
                                    ? false
                                    : true
                                  : false,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.appIdDisplay
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "appId：", prop: "appId" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: _vm.disabled, clearable: "" },
                            model: {
                              value: _vm.pointForm.appId,
                              callback: function ($$v) {
                                _vm.$set(_vm.pointForm, "appId", $$v)
                              },
                              expression: "pointForm.appId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.appIdDisplay
                    ? _c(
                        "el-form-item",
                        {
                          staticClass: "jumpUrl",
                          staticStyle: { display: "block !important" },
                          attrs: { label: "跳转参数：" },
                        },
                        [
                          _vm._l(_vm.jumpParamArray, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "jumpUrlBox" },
                              [
                                _c(
                                  "el-input",
                                  {
                                    staticClass: "keyInput",
                                    attrs: { disabled: _vm.disabled },
                                    model: {
                                      value: item.key,
                                      callback: function ($$v) {
                                        _vm.$set(item, "key", $$v)
                                      },
                                      expression: "item.key",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("Key:"),
                                    ]),
                                  ],
                                  2
                                ),
                                _c(
                                  "el-input",
                                  {
                                    staticClass: "valueInput",
                                    attrs: { disabled: _vm.disabled },
                                    model: {
                                      value: item.value,
                                      callback: function ($$v) {
                                        _vm.$set(item, "value", $$v)
                                      },
                                      expression: "item.value",
                                    },
                                  },
                                  [
                                    _c("template", { slot: "prepend" }, [
                                      _vm._v("Value:"),
                                    ]),
                                  ],
                                  2
                                ),
                                !_vm.disabled
                                  ? _c(
                                      "el-button",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: index >= 1,
                                            expression: "index >= 1",
                                          },
                                        ],
                                        staticClass: "reduce",
                                        on: {
                                          click: function ($event) {
                                            return _vm.reduceClickFun(index)
                                          },
                                        },
                                      },
                                      [_vm._v("-")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          !_vm.disabled
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "add",
                                  on: { click: _vm.jumpUrlClick },
                                },
                                [_vm._v("+")]
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所在模块：", prop: "module" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            disabled: _vm.disabled,
                            multiple: "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.pointForm.module,
                            callback: function ($$v) {
                              _vm.$set(_vm.pointForm, "module", $$v)
                            },
                            expression: "pointForm.module",
                          },
                        },
                        _vm._l(_vm.moduleList, function (items) {
                          return _c("el-option", {
                            key: items.label,
                            attrs: {
                              label: items.label,
                              value: items.label,
                              disabled:
                                _vm.Form1IMG == "Form1IMG"
                                  ? false
                                  : items.value === 777
                                  ? false
                                  : true,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { display: "block" },
                      attrs: { label: "图片：", prop: _vm.Form1IMG },
                    },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "elUpload",
                          staticClass: "upload-demo",
                          class: { hide: _vm.disabled },
                          attrs: {
                            disabled: _vm.disabled,
                            action: "",
                            "list-type": "picture-card",
                            "auto-upload": false,
                            multiple: false,
                            "on-change": _vm.imageSuccessFun,
                            "on-remove": _vm.imageListRemove,
                            "file-list": _vm.fileList,
                            limit: 2,
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-plus" }),
                          !_vm.disabled
                            ? _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [_vm._v(" 只能上传jpg/png文件，且不超过240kb ")]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否启用：", prop: "status" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          disabled: _vm.disabled,
                          "active-color": "#13ce66",
                          "inactive-color": "#E3E3E3",
                        },
                        on: { change: _vm.Form1Switch },
                        model: {
                          value: _vm.switchOnoff,
                          callback: function ($$v) {
                            _vm.switchOnoff = $$v
                          },
                          expression: "switchOnoff",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dialogVisible,
              expression: "dialogVisible",
            },
          ],
          staticClass: "imgPopup",
          on: { click: () => (_vm.dialogVisible = false) },
        },
        [
          _c("img", {
            attrs: { src: _vm.dialogImageUrl, alt: "" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return (() => {}).apply(null, arguments)
              },
            },
          }),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }