<template>
  <!-- 广告管理 -->
  <div v-loading="pageLoading" class="advertisementManage">
    <template v-if="channelList.length">
      <el-tabs v-model="channelNo" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        ></el-tab-pane>
      </el-tabs>
      <el-button type="primary" size="small" @click="addFun">添加</el-button>
      <Table
        :table-data.sync="tableData"
        :title-name="titleName"
        :operation="true"
        :col-class-type="true"
        :drag-onoff="true"
        operation-width="150"
        @dragFinsh="dragFinsh"
      >
        <template slot-scope="scope">
          <div>
            <template>
              <el-button
                type="text"
                size="small"
                sort="primary"
                @click="updateBtn(scope.scopeRow)"
                >编辑</el-button
              >
            </template>
            <template>
              <el-button
                type="text"
                size="small"
                sort="primary"
                @click="seeFun(scope.scopeRow)"
                >查看</el-button
              >
            </template>
            <template>
              <el-button
                type="text"
                size="small"
                sort="danger"
                @click="deleteFun(scope.scopeRow)"
                >删除</el-button
              >
            </template>
          </div>
        </template>
      </Table>
    </template>
    <div
      v-else-if="emptyFlag"
      style="width: 100%; font-size: 18px; text-align: center"
    >
      <img src="~@/assets/images/home/empty.png" alt />
      <div>暂无权限，请联系管理员</div>
    </div>
    <charterPopup
      v-if="show"
      width="820"
      :disabled="disabled"
      :header-text="headerText"
      @closePopupFun="closePopupFun"
      @confirmFun="confirmFun"
    >
      <el-form
        ref="pointForm"
        :model="pointForm"
        :rules="rules"
        label-width="140px"
        label-position="right"
        class="demo-form dialog-form"
      >
        <el-form-item style="display: block" label="广告描述：" prop="advDesc">
          <el-input
            v-model="pointForm.advDesc"
            maxlength="200"
            :disabled="disabled"
            type="textarea"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告类型：" prop="advType">
          <el-select
            v-model="pointForm.advType"
            :disabled="disabled"
            placeholder="请选择"
            @change="advTypeChange"
          >
            <el-option
              v-for="items in advertisementClassList"
              :key="items.value"
              :label="items.label"
              :value="items.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="位置：">
          <el-input
            v-model="pointForm.position"
            :seat-value="seatValue"
            disabled="disabled"
          ></el-input>
        </el-form-item>
        <el-form-item label="有效期起：" prop="startDate">
          <el-date-picker
            v-model="pointForm.startDate"
            :disabled="disabled"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="有效期止：" prop="deadDate">
          <el-date-picker
            v-model="pointForm.deadDate"
            :disabled="disabled"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            default-time="23:59:59"
            placeholder="选择时间"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="点击跳转：" prop="jumpLink">
          <el-select
            v-model="pointForm.jumpLink"
            :disabled="disabled"
            placeholder="请选择"
            @change="jumpLinkChange"
          >
            <el-option
              v-for="items in jumpLinkList"
              :key="items.value"
              :label="items.label"
              :value="items.value"
              :disabled="
                pointForm.advType == 3
                  ? items.value === 0
                    ? false
                    : true
                  : false
              "
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="appIdDisplay" label="appId：" prop="appId">
          <el-input
            v-model="pointForm.appId"
            :disabled="disabled"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="appIdDisplay"
          label="跳转参数："
          class="jumpUrl"
          style="display: block !important"
        >
          <div
            v-for="(item, index) in jumpParamArray"
            :key="index"
            class="jumpUrlBox"
          >
            <el-input v-model="item.key" :disabled="disabled" class="keyInput">
              <template slot="prepend">Key:</template>
            </el-input>
            <el-input
              v-model="item.value"
              :disabled="disabled"
              class="valueInput"
            >
              <template slot="prepend">Value:</template>
            </el-input>
            <el-button
              v-if="!disabled"
              v-show="index >= 1"
              class="reduce"
              @click="reduceClickFun(index)"
              >-</el-button
            >
          </div>
          <el-button v-if="!disabled" class="add" @click="jumpUrlClick"
            >+</el-button
          >
        </el-form-item>
        <el-form-item label="所在模块：" prop="module">
          <el-select
            v-model="pointForm.module"
            :disabled="disabled"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="items in moduleList"
              :key="items.label"
              :label="items.label"
              :value="items.label"
              :disabled="
                Form1IMG == 'Form1IMG'
                  ? false
                  : items.value === 777
                  ? false
                  : true
              "
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片：" :prop="Form1IMG" style="display: block">
          <el-upload
            ref="elUpload"
            :disabled="disabled"
            action
            list-type="picture-card"
            class="upload-demo"
            :auto-upload="false"
            :multiple="false"
            :on-change="imageSuccessFun"
            :on-remove="imageListRemove"
            :file-list="fileList"
            :limit="2"
            :class="{ hide: disabled }"
          >
            <i class="el-icon-plus"></i>
            <div v-if="!disabled" slot="tip" class="el-upload__tip">
              只能上传jpg/png文件，且不超过240kb
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="是否启用：" prop="status">
          <el-switch
            v-model="switchOnoff"
            :disabled="disabled"
            active-color="#13ce66"
            inactive-color="#E3E3E3"
            @change="Form1Switch"
          ></el-switch>
        </el-form-item>
      </el-form>
    </charterPopup>
    <div
      v-show="dialogVisible"
      class="imgPopup"
      @click="() => (dialogVisible = false)"
    >
      <img :src="dialogImageUrl" alt @click.stop="() => {}" />
    </div>
  </div>
</template>

<script>
import { uploadFileAPI, getCurrentUserChannelInfoAPI } from "@/api/lib/api.js";
import {
  addAPI,
  tableListAPI,
  tableDeleteAPI,
  tableUpdateAPI,
  tableDragAPI,
} from "@/api/lib/advertisement.js";
import host from "@/api/static/host.js";
export default {
  data() {
    return {
      pageLoading: true,
      emptyFlag: false,
      channelNo: null,
      channelName: null,
      channelList: [],
      Form1IMG: "Form1IMG",
      jumpParamArray: [
        {
          key: "",
          value: "",
        },
      ],
      appIdRule: "",
      appIdDisplay: false,
      advertisementClassList: [
        { label: "底部广告", value: 0, seatText: "底部", seatValue: 0 },
        { label: "banner广告", value: 1, seatText: "顶部", seatValue: 1 },
        { label: "启动广告", value: 2, seatText: "全屏", seatValue: 2 },
        { label: "文字提示", value: 3, seatText: "顶部", seatValue: 3 },
      ],
      jumpLinkList: [
        { label: "汽车订票", value: 2 },
        { label: "定制客运", value: 1 },
        { label: "包车", value: 4 },
        { label: "租车", value: 5 },
        { label: "定制班线", value: 6 },
        { label: "村通客运", value: 7 },
        { label: "不跳转", value: 0 },
        { label: "小程序", value: 887 },
      ],
      moduleList: [
        { label: "汽车订票", value: 2 },
        { label: "定制客运", value: 1 },
        { label: "包车", value: 4 },
        { label: "租车", value: 5 },
        { label: "定制班线", value: 6 },
        { label: "村通客运", value: 7 },
        { label: "启动页", value: 888 },
        { label: "首页全局", value: 777 },
      ],
      headerText: "新增",
      pointForm: {
        advType: null, //广告类型
        position: null, //广告位置
        startDate: "", //  有效期起
        deadDate: "", //有效期止
        jumpLink: "", //点击跳转
        module: [], //所在模块
        advDesc: "", //广告描述
        picUrl: "", //广告图片URL
        status: 0, //是否启用
        appId: "",
        jumpParam: "",
      },
      //   规则校验
      rules: {
        advType: [
          { required: true, message: "请选择广告类型", trigger: "change" },
        ],
        startDate: [
          {
            required: true,
            trigger: "change",
            validator: this.startDateChange,
          },
        ],
        deadDate: [
          { required: true, trigger: "change", validator: this.deadDateChange },
        ],
        jumpLink: [
          { required: true, message: "请选择跳转的模块", trigger: "change" },
        ],
        module: [
          { required: true, message: "请选择广告所在模块", trigger: "change" },
        ],
        advDesc: [
          { required: true, message: "请输入广告描述", trigger: "blur" },
        ],
        Form1IMG: [
          {
            required: true,
            validator: this.imgRules,
            trigger: "blur",
          },
        ],
        appId: [{ required: true, message: "请输入appId", trigger: "blur" }],
      },
      switchOnoff: false, //是否启用开关
      seatValue: null,
      dialogVisible: false,
      dialogImageUrl: "",
      imgBase64: "",
      imgMsg: "",
      imgSize: 0,
      fileList: [],
      disabled: false,
      show: false,
      popupStatus: "",
      tableData: [],
      titleName: [
        {
          title: "广告描述",
          props: "advDesc",
          width: 200,
        },
        {
          title: "图片",
          props: "picUrl",
          render: (h, params, index) => {
            return h("i", {
              class: "iconfont its_gy_image",
              style: {
                "font-size": "18px",
                cursor: "pointer",
                color: "#336FFE",
              },
              on: {
                click: () => {
                  this.dialogVisible = true;
                  this.dialogImageUrl = host.imgURL + params.picUrl;
                },
              },
            });
          },
        },
        {
          title: "广告类型",
          props: "advType",
          SpecialJudgment: (res) => {
            switch (res) {
              case 0:
                return "底部广告";
              case 1:
                return "banner广告";
              case 2:
                return "启动广告";
              case 3:
                return "文字提示";
              default:
                return "";
            }
          },
        },
        {
          title: "位置",
          props: "position",
          SpecialJudgment: (res) => {
            switch (res) {
              case 0:
                return "底部";
              case 1:
                return "顶部";
              case 2:
                return "全屏";
              case 3:
                return "顶部";
              default:
                return "";
            }
          },
        },
        {
          title: "所在模块",
          props: "module",
        },
        {
          title: "点击跳转",
          props: "jumpLink",
          SpecialJudgment: (res) => {
            switch (res) {
              case 2:
                return "汽车订票";
              case 1:
                return "城际专车";
              case 4:
                return "包车";
              case 5:
                return "租车";
              case 6:
                return "定制班线";
              case 7:
                return "村通客运";
              case 887:
                return "小程序";
              case 999:
                return "不跳转";
              default:
                return "";
            }
          },
        },
        {
          title: "appId",
          props: "appId",
          width: 150,
        },
        {
          title: "跳转参数",
          props: "jumpParam",
          width: 150,
        },
        {
          title: "有效期起",
          props: "startDate",
          width: 200,
        },
        {
          title: "有效期止",
          props: "deadDate",
          width: 200,
        },
        {
          title: "启用",
          props: "status",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status === 0 ? true : false,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  console.log(params);
                  let obj = this.deepClone(params);
                  console.log(obj);
                  if (obj.status == 1) {
                    obj.status = 0;
                  } else {
                    obj.status = 1;
                  }
                  tableUpdateAPI(obj).then((res) => {
                    if (res.code == "SUCCESS") {
                      this.$message.success("修改成功");
                      this.renderData();
                      this.clearForm();
                    }
                  });
                },
              },
            });
          },
        },
      ],
    };
  },
  mounted() {
    this.getQueryChannelList();
    AMap.plugin("AMap.Geocoder", function () {
      var geocoder = new AMap.Geocoder({
        // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
        radius: 1000,
        extensions: "all",
      });

      var lnglat = [104.40183, 30.126423];

      geocoder.getAddress(lnglat, function (status, result) {
        if (status === "complete" && result.info === "OK") {
          // result为对应的地理位置详细信息
          console.log(result);
        }
      });
    });
  },
  methods: {
    //点击渠道tab项
    handleClick(val) {
      this.channelName = val.label;
      this.renderData();
    },
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI()
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.channelList = res.data || [];
            this.channelNo = res.data[0]?.channelNo;
            this.channelName = res.data[0]?.channelName;
            if (!res.data.length) {
              this.$message.error("暂无权限，请联系管理员");
              this.emptyFlag = true;
              this.pageLoading = false;
              return;
            }
          }
          this.pageLoading = false;
          this.renderData();
        })
        .catch(() => {
          this.pageLoading = false;
        });
    },
    // 点击跳转参数减号按钮
    reduceClickFun(index) {
      this.jumpParamArray.splice(index, 1);
    },
    // 点击跳转参数添加按钮
    jumpUrlClick() {
      console.log(this.jumpParamArray);
      if (this.appIdDisplay) {
        let length = this.jumpParamArray.length;
        if (this.jumpParamArray[length - 1].key == "") {
          this.$message.error("请填写跳转参数Key值！");
        } else {
          this.jumpParamArray.push({
            key: "",
            value: "",
          });
        }
      }
    },
    // 跳转下拉框change事件
    jumpLinkChange(val) {
      console.log(val);
      if (val == 887) {
        this.appIdDisplay = true;
        this.appIdRule = "appIdRule";
      } else {
        this.pointForm.appId = "";
        this.appIdRule = "";
        this.appIdDisplay = false;
        this.$refs.pointForm.clearValidate(["appIdRule"]);
        this.jumpParamArray = [
          {
            key: "",
            value: "",
          },
        ];
      }
    },
    //
    startDateChange(rule, value, callback) {
      if (!this.pointForm.startDate) {
        callback("请选择起始时间");
      } else {
        if (!this.pointForm.deadDate) {
          callback();
        } else {
          if (this.bjDate(this.pointForm.startDate, this.pointForm.deadDate)) {
            callback();
          } else {
            callback("起始时间不得大于结束时间！");
          }
        }
      }
    },
    // 有效期结束时间change事件
    deadDateChange(rule, value, callback) {
      if (!this.pointForm.deadDate) {
        callback("请选择结束时间");
      } else {
        if (!this.pointForm.startDate) {
          callback();
        } else {
          if (this.bjDate(this.pointForm.startDate, this.pointForm.deadDate)) {
            callback();
          } else {
            callback("结束时间不能小于开始时间！");
          }
        }
      }
    },
    // 计算时间大小
    bjDate(date, date1) {
      var date = new Date(date);
      var date1 = new Date(date1);
      if (date.getTime() - date1.getTime() < 0) {
        // 第二个参数时间大
        return true;
      } else {
        // 第一个参数时间大
        return false;
      }
    },
    dragFinsh(data) {
      let dragArray = data.map((item) => item.id);
      tableDragAPI({ sorts: dragArray }).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("排序已生效");
          this.renderData();
        }
      });
    },
    // 点击添加按钮
    addFun() {
      this.show = true;
      this.headerText = "添加";
      this.popupStatus = "add";
      this.jumpParamArray = [
        {
          key: "",
          value: "",
        },
      ];
    },
    advTypeChange(val) {
      console.log(val);
      this.pointForm.module = [];
      // 如果选择的是文字提示
      if (val == 3) {
        this.pointForm.jumpLink = 0;
        this.Form1IMG = "";
      } else {
        this.Form1IMG = "Form1IMG";
      }
      this.advertisementClassList.map((item) => {
        if (item.value == val) {
          this.pointForm.position = item.seatText;
          this.seatValue = item.seatValue;
        }
      });
    },
    // 弹窗确定按钮
    confirmFun() {
      this.$refs.pointForm.validate((valid) => {
        if (valid) {
          switch (this.popupStatus) {
            case "add":
              this.addDataFun();
              break;
            case "update":
              this.updateDataFun();
              break;
          }
        }
      });
    },
    // 弹窗取消按钮
    closePopupFun() {
      this.clearForm();
    },
    // 添加方法
    addDataFun() {
      // -----------------先上传图片-------------------
      if (this.Form1IMG == "") {
        this.pointForm.picUrl = "";
        let obj = this.deepClone(this.pointForm);
        let mod = "";
        this.pointForm.module.map((item, index) => {
          if (index == this.pointForm.module.length - 1) {
            mod += item;
          } else {
            mod += item + ",";
          }
        });
        obj.status = this.switchOnoff == true ? 0 : 1;
        obj.module = mod;
        obj.position = this.seatValue;
        let jumpParamText = "";
        this.jumpParamArray.map((item, index) => {
          if (item.key && item.value) {
            jumpParamText += "&" + item.key + "=" + item.value;
          }
        });
        obj.jumpParam = jumpParamText;
        obj.channelNo = this.channelNo;
        addAPI(obj).then((res) => {
          if (res.code == "SUCCESS") {
            this.$message.success("添加成功");
            this.renderData();
            this.clearForm();
          }
        });
      } else {
        // FormData 对象
        let form = new FormData();
        // 文件对象
        form.append("files", this.fileList[0].raw);
        new Promise((resolve, reject) => {
          uploadFileAPI(form).then((res) => {
            if (res.code == "SUCCESS") {
              resolve(res.data);
            }
          });
        }).then((jump) => {
          this.pointForm.picUrl = jump[0];
          let obj = this.deepClone(this.pointForm);
          let mod = "";
          this.pointForm.module.map((item, index) => {
            if (index == this.pointForm.module.length - 1) {
              mod += item;
            } else {
              mod += item + ",";
            }
          });
          obj.status = this.switchOnoff == true ? 0 : 1;
          obj.module = mod;
          obj.position = this.seatValue;
          let jumpParamText = "";
          this.jumpParamArray.map((item, index) => {
            if (item.key && item.value) {
              jumpParamText += "&" + item.key + "=" + item.value;
            }
          });
          obj.jumpParam = jumpParamText;
          obj.channelNo = this.channelNo;
          addAPI(obj).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("添加成功");
              this.renderData();
              this.clearForm();
            }
          });
        });
      }
    },
    // 表格渲染方法
    renderData() {
      tableListAPI({ channelNo: this.channelNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    // 清空
    clearForm() {
      this.appIdDisplay = false;
      this.disabled = false;
      this.show = false;
      this.switchOnoff = false;
      this.fileList = [];
      this.imgBase64 = "";
      this.imgMsg = "";
      this.imgSize = 0;
      this.pointForm = {
        advType: null, //广告类型
        position: null, //广告位置
        startDate: "", //  有效期起
        deadDate: "", //有效期止
        jumpLink: "", //点击跳转
        module: [], //所在模块
        advDesc: "", //广告描述
        picUrl: "", //广告图片URL
        status: 0, //是否启用
      };
    },
    //   开关change事件
    Form1Switch(val) {
      if (val) {
        this.pointForm.status = 0;
      } else {
        this.pointForm.status = 1;
      }
    },
    // 选择完图片后转为base64并保存
    imageSuccessFun(file, fileList) {
      this.fileList = [file];
      let that = this;
      this.imgMsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      this.imgSize = file.size;
      var a = new FileReader();
      a.readAsDataURL(file.raw); //读取文件保存在result中
      a.onload = function (e) {
        that.imgBase64 = e.target.result; //读取的结果在result中
        that.$refs.pointForm.validateField("Form1IMG");
      };
    },
    // 移除图片事件
    imageListRemove(file, fileList) {
      this.imgBase64 = "";
      this.imgMsg = "";
      this.imgSize = "";
      this.fileList = [];
      this.$refs.pointForm.clearValidate("Form1IMG");
    },
    // 自定义图片规则
    imgRules(rule, value, callback) {
      if (this.imgBase64 == "") {
        if (this.fileList.length > 0 && this.fileList[0].size == undefined) {
          callback();
        } else {
          callback("请上传图片！");
        }
      } else {
        if (this.imgMsg === "jpg" || this.imgMsg === "png") {
          if (this.imgSize < 245760) {
            callback();
          } else {
            callback("图片大小超过限制！");
          }
        } else {
          callback("上传文件格式不正确！");
        }
      }
    },
    // 点击编辑按钮
    updateBtn({
      id,
      advType,
      position,
      startDate,
      deadDate,
      jumpLink,
      module,
      advDesc,
      picUrl,
      status,
      appId,
      jumpParam,
    }) {
      if (advType === 3) {
        this.Form1IMG = "";
      } else {
        this.Form1IMG = "Form1IMG";
      }
      this.headerText = "编辑";
      this.popupStatus = "update";
      this.disabled = false;
      this.show = true;
      this.seatValue = position;
      this.pointForm = {
        id,
        advType,
        position:
          position === 0
            ? "底部"
            : position === 1 || position === 3
            ? "顶部"
            : position === 2
            ? "全屏"
            : "",
        startDate,
        deadDate,
        jumpLink,
        module: module.split(","),
        advDesc,
        picUrl,
        status,
        appId,
        jumpParam,
      };
      // 如果是跳转小程序
      if (jumpLink == 887) {
        this.jumpParamArray = [];
        this.appIdDisplay = true;
        jumpParam.split("&").map((item) => {
          if (item != "") {
            this.jumpParamArray.push({
              key: item.split("=")[0],
              value: item.split("=")[1],
            });
          }
        });
      }
      this.fileList = [{ url: host.imgURL + picUrl }];
      if (status === 0) {
        this.switchOnoff = true;
      } else {
        this.switchOnoff = false;
      }
    },
    // 更新方法
    updateDataFun() {
      new Promise((res, rej) => {
        if (this.fileList[0].size != undefined) {
          // -----------------先上传图片-------------------
          // FormData 对象
          let form = new FormData();
          // 文件对象
          form.append("files", this.fileList[0].raw);
          uploadFileAPI(form).then((params) => {
            if (params.code == "SUCCESS") {
              this.pointForm.picUrl = params.data[0];
              res(this.pointForm);
            }
          });
        } else {
          res(this.pointForm);
        }
      }).then((rs) => {
        let obj = this.deepClone(this.pointForm);
        let mod = "";
        this.pointForm.module.map((item, index) => {
          if (index == this.pointForm.module.length - 1) {
            mod += item;
          } else {
            mod += item + ",";
          }
        });
        obj.status = this.switchOnoff == true ? 0 : 1;
        obj.module = mod;
        obj.position = this.seatValue;
        let jumpParamText = "";
        this.jumpParamArray.map((item, index) => {
          jumpParamText += "&" + item.key + "=" + item.value;
        });
        obj.jumpParam = jumpParamText;
        tableUpdateAPI(obj).then((res) => {
          if (res.code == "SUCCESS") {
            this.$message.success("修改成功");
            this.renderData();
            this.clearForm();
          }
        });
      });
    },
    // 点击查看的时候
    seeFun({
      id,
      advType,
      position,
      startDate,
      deadDate,
      jumpLink,
      module,
      advDesc,
      picUrl,
      status,
      appId,
      jumpParam,
    }) {
      if (advType === 3) {
        this.Form1IMG = "";
      } else {
        this.Form1IMG = "Form1IMG";
      }
      this.headerText = "查看";
      this.popupStatus = "";
      this.disabled = true;
      this.show = true;
      this.seatValue = position;
      this.pointForm = {
        id,
        advType,
        position:
          position === 0
            ? "底部"
            : position === 1 || position === 3
            ? "顶部"
            : position === 2
            ? "全屏"
            : "",
        startDate,
        deadDate,
        jumpLink,
        module: module.split(","),
        advDesc,
        picUrl,
        status,
        appId,
        jumpParam,
      };
      // 如果是跳转小程序
      if (jumpLink == 887) {
        this.jumpParamArray = [];
        this.appIdDisplay = true;
        jumpParam.split("&").map((item) => {
          if (item != "") {
            this.jumpParamArray.push({
              key: item.split("=")[0],
              value: item.split("=")[1],
            });
          }
        });
      }
      this.fileList = [{ url: host.imgURL + picUrl }];
      if (status === 0) {
        this.switchOnoff = true;
      } else {
        this.switchOnoff = false;
      }
    },
    // 删除数据
    deleteFun(scope) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        type: "warning",
        message: h("p", null, [h("span", null, "确认删除？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          tableDeleteAPI({ advId: scope.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("删除成功");
              this.renderData();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.jumpUrl {
  position: relative;
  .jumpUrlBox {
    width: 100%;
    display: block;
    padding-top: 4px;
    position: relative;
  }
  .add {
    position: absolute;
    right: 14px;
    top: 8px;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 0 !important;
  }
  .reduce {
    position: absolute;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    padding: 0 !important;
    right: 14px;
    top: 8px;
  }
}
/deep/.el-form-item {
  display: inline-block;
  .keyInput {
    width: 49%;
    .el-input__inner {
      width: 147px;
    }
  }
  .valueInput {
    width: 49%;
    .el-input__inner {
      width: 200px;
    }
  }
  .el-input-group__prepend {
    width: 40px;
    padding: 0 6px;
  }
}
.Table {
  margin-top: 20px;
}
.imgPopup {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  img {
    max-width: 70%;
    max-height: 70%;
  }
}
/deep/.hide .el-upload--picture-card {
  display: none;
}
/deep/.el-dialog__body {
  text-align: center !important;
}
/deep/.el-upload-list__item-status-label {
  display: none !important;
}
/deep/.el-dialog__header {
  padding: 26px;
}
/deep/ .el-textarea textarea {
  resize: none !important;
  height: 80px;
  width: 540px !important;
}
.advertisementManage {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  height: 100%;
  box-sizing: border-box;
}
</style>
